// export const baseUrl = "http://127.0.0.1:8000"
export const baseUrl = "https://qatesting.kerrijames.co"
export const getGoals = "/api/getGoals/"
export const addGoal = "/api/add_goal"
export const editGoalUrl = "/api/edit_goal/"
export const deleteGoalUrl = "/api/delete_goal/"
export const getCheckpoints = "/api/getCheckpoints/"
export const editCheckpointUrl = "/api/edit_checkpoint/"
export const reorderCheckpoints = "/api/reorder_checkpoints"
export const deleteCheckpointUrl = "/api/delete_checkpoint/"
export const getContacts = "/api/getContacts/"
export const deleteContactUrl = "/api/delete_contact/"
export const getContactLog = "/api/getContactLog/"
