import React, { useState, useEffect, useRef } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chip } from 'primereact/chip';
import { useParams } from 'react-router-dom';
import { addGoal, editGoalUrl, deleteGoalUrl, getGoals, getCheckpoints, editCheckpointUrl, reorderCheckpoints, deleteCheckpointUrl, getContacts, deleteContactUrl, getContactLog } from '../../utils/urls';
import axios from '../../utils/axios'
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toolbar } from 'primereact/toolbar';
import './goals.css'

export default function Goals() {
    
    const { locationId } = useParams();
    const getGoalUrl = `${getGoals}${locationId}`
    const [chips, setChips] = useState([]);
    const [newChipText, setNewChipText] = useState('');
    const [mounted, setMounted] = useState(false)
    const [goal, setGoal] = useState('');
    const [goalDiscription, setGoalDiscription] = useState('');
    const [goalTitles, setGoalTitles] = useState([]);
    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [checkpointFilter, setCheckpointFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [checkpoints, setCheckpoints] = useState([]);
    const [checkpointVisible, setCheckpointVisible] = useState(false);
    const [contactGoal, setContactGoal] = useState('');
    const [checkpointGoalId, setCheckpointGoalId] = useState('');
    const [checkpointGoalName, setCheckpointGoalName] = useState('');
    const [contacts, setContacts] = useState([]);
    const [columns, setColumns] = useState([])
    const [contactVisible, setContactVisible] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // full_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        // representative: { value: null, matchMode: FilterMatchMode.IN },
        // status: { value: null, matchMode: FilterMatchMode.EQUALS },
        // verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [auditLogs, setAuditLogs] = useState([]);
    const [auditLogVisible, setAuditLogVisible] = useState(false);
    const [auditLogFilters, setAuditLogFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        checkpoint_name: { value: null, matchMode: FilterMatchMode.IN }
    });
    const [auditLogLoading, setAuditLogLoading] = useState(true);
    const [goalCheckpoint, setGoalCheckpoints] = useState([]);
    const [auditLogGlobalFilterValue, setAuditLogGlobalFilterValue] = useState('');

    const [goalEditDialog, setGoalEditDialog] = useState(false);
    const [editSubmitted, setEditSubmitted] = useState(false);
    const [editGoal, setEditGoal] = useState('');
    const [editGoalId, setEditGoalId] = useState('');
    const [editGoalDiscription, setEditGoalDiscription] = useState('');
    const [editChips, setEditChips] = useState([]);
    const [newEditChipText, setNewEditChipText] = useState('');

    const [checkpointEditDialog, setCheckpointEditDialog] = useState(false);
    const [editCheckpointSubmitted, setEditCheckpointSubmitted] = useState(false);
    const [editCheckpoint, setEditCheckpoint] = useState('');
    const [editCheckpointId, setEditCheckpointId] = useState('');
    const [editCheckpointDiscription, setEditCheckpointDiscription] = useState('');


    useEffect(() => {
        axios
            .get(getGoalUrl, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                console.log(response.data)
                setProducts(response.data.goals)
                setGoalTitles(response.data.titles)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
    }, [getGoalUrl, mounted]);

    const openNew = () => {
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setGoal('')
        setGoalDiscription('')
        setChips([])
        setNewChipText('')
    };

    const hideEditDialog = () => {
        setEditSubmitted(false);
        setGoalEditDialog(false);
        setEditGoal('')
        setEditGoalId('')
        setEditGoalDiscription('')
        setEditChips([])
        setNewEditChipText('')
    };

    const hideCheckpointEditDialog = () => {
        setEditCheckpointSubmitted(false);
        setCheckpointEditDialog(false);
        setEditCheckpoint('')
        setEditCheckpointId('')
        setEditCheckpointDiscription('')
    };


    const saveProduct = () => {
        setSubmitted(true);

        if (goal.trim() && !goalTitles.includes(goal.trim())) {

            const body = {
                "location_id" : locationId,
                "goal" : goal,
                "discription" : goalDiscription,
                "checkpoints" : chips
            }

            axios
              .post(addGoal, body, {
                headers: { "Content-Type": "application/json" },
              })
              .then((response) => {
                if (response.status === 201){
                    hideDialog()
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Goal Created', life: 3000 });
                    setMounted(!mounted)       
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                }
              })
              .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
              });
        }
        else if (goalTitles.includes(goal.trim())){
            toast.current.show({severity:'error', summary: 'Error', detail:'Goal name should be unique', life: 3000});
        }
    };

    const saveEditGoal = () => {
        setEditSubmitted(true);

        if (editGoal.trim()) {

        const body = {
            "goal_id" : editGoalId,
            "name" : editGoal,
            "discription" : editGoalDiscription,
            "checkpoints" : editChips
        }

        const editGoalURL = `${editGoalUrl}${editGoalId}`

        axios
            .post(editGoalURL, body, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                hideEditDialog()
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Goal Updated', life: 3000 });
                setMounted(!mounted)       
            }
            else if(response.status === 207){
                toast.current.show({severity:'error', summary: 'Error', detail:'Goal name should be unique', life: 3000});
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
        }
        // else if (goalTitles.includes(goal.trim())){
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Goal name should be unique', life: 3000});
        // }
    };

    const saveEditCheckpoint = () => {
        setEditCheckpointSubmitted(true);

        if (editCheckpoint.trim()) {

            const body = {
                "checkpoint_id" : editCheckpointId,
                "name" : editCheckpoint,
                "discription" : editCheckpointDiscription
            }

            const editCheckpointURL = `${editCheckpointUrl}${editCheckpointId}`

            axios
                .post(editCheckpointURL, body, {
                headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                if (response.status === 200){
                    hideCheckpointEditDialog()
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Checkpoint Updated', life: 3000 });
                    setCheckpoints(response.data)
                    setMounted(!mounted)
                }
                else if(response.status === 207){
                    toast.current.show({severity:'error', summary: 'Error', detail:'GoaCheckpointl name should be unique', life: 3000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                }
                })
                .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                });
        }
    };


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between goal-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
            <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
        </div>
    );

    const checkpointHeader = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between goal-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setCheckpointFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <div className="dialog-footer">
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
        </div>
    );

    const goalEditDialogFooter = (
        <div className="dialog-footer">
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideEditDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveEditGoal} />
        </div>
    );

    const checkpointEditDialogFooter = (
        <div className="dialog-footer">
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideCheckpointEditDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveEditCheckpoint} />
        </div>
    );

    const handleAddChip = () => {
        const trimmedText = newChipText.trim();
    
        if (trimmedText !== '' && !chips.includes(trimmedText)) {
            setChips([...chips, trimmedText]);
            setNewChipText('');
        }
        else if (chips.includes(trimmedText)){
            toast.current.show({severity:'error', summary: 'Error', detail:'Checkpoint should be unique', life: 3000});
        }
    };

    const handleAddEditChip = () => {
        const trimmedText = newEditChipText.trim();
    
        if (trimmedText !== '' && !editChips.includes(trimmedText)) {
            setEditChips([...editChips, trimmedText]);
            setNewEditChipText('');
        }
        else if (editChips.includes(trimmedText)){
            toast.current.show({severity:'error', summary: 'Error', detail:'Checkpoint should be unique', life: 3000});
        }
    };

    // const handleRemoveChip = (value) => {
        
    //     console.log(chips)
    //     const indexToRemove = chips.indexOf(value);

    //     if (indexToRemove !== -1) {
    //         chips.splice(indexToRemove, 1);
    //     }

    //     console.log(chips)
    // };

    const handleRemoveChip = (value) => {
        setChips((prevChips) => {
            const newChips = prevChips.filter((chip) => chip !== value);
            return newChips;
        });
    };

    const handleRemoveEditChip = (value) => {
        setEditChips((prevChips) => {
            const newChips = prevChips.filter((chip) => chip !== value);
            return newChips;
        });
    };

    const handleCheckpointView = (rowData) => {

        const checkpointUrl = `${getCheckpoints}${rowData.goal_id}`

        axios
            .get(checkpointUrl, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                setCheckpointGoalId(rowData.goal_id)
                setCheckpointGoalName(rowData.title)
                setCheckpoints(response.data)
                setCheckpointVisible(true)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
    }

    const handleContactView = (goal_id) => {

        const contactUrl = `${getContacts}${goal_id}`

        axios
            .get(contactUrl, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                setContactGoal(goal_id)
                setContacts(response.data.contacts)
                setColumns(response.data.column)
                setContactVisible(true)
                setLoading(false)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
    }

    const handleAuditLog = (contact_id) => {

        const contactLogUrl = `${getContactLog}${contact_id}`
        const body = {
            "goal_id": contactGoal
        }
        axios
            .post(contactLogUrl, body, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                console.log(response.data)
                setAuditLogVisible(true)
                setAuditLogs(response.data.logs)
                setGoalCheckpoints(response.data.checkpoints)
                setAuditLogLoading(false)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
    }

    const checkpointsBodyTemplate = (rowData) => {
        return <Button label={ `${rowData.num_checkpoints} checkpoints` } icon="pi pi-external-link" iconPos="right" onClick={() => handleCheckpointView(rowData)} />;
    };

    const contactsBodyTemplate = (rowData) => {
        return <Button label={`${rowData.num_contacts} contacts` } icon="pi pi-external-link" iconPos="right" onClick={() => handleContactView(rowData.goal_id)}/>;
    };

    const auditLogBodyTemplate = (rowData) => {
        return <Button label='Audit Log' icon="pi pi-external-link" iconPos="right" onClick={() => handleAuditLog(rowData.contact_id)} />;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const checkedBodyTemplate = (rowData, field) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData[field], 'false-icon pi-times-circle': !rowData[field] })} style={{ fontSize: '1.3rem', color: rowData[field] ? 'green' : 'red' }}></i>;
    };

    const checkpointItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const checkpointFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={goalCheckpoint}
                itemTemplate={checkpointItemTemplate}
                onChange={(e) => {options.filterApplyCallback(e.value); console.log(e.value)}}
                optionLabel="name"
                placeholder="Select Checkpoints"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '1rem' }}
            />
        );
    };

    const onAuditLogGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...auditLogFilters };

        _filters['global'].value = value;

        setAuditLogFilters(_filters);
        setAuditLogGlobalFilterValue(value);
    };

    const auditLogRenderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={auditLogGlobalFilterValue} onChange={onAuditLogGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const handleEditGoal = (rowData) => {
        setEditSubmitted(false)
        setEditGoalId(rowData.goal_id)
        setEditGoal(rowData.title)
        setEditGoalDiscription(rowData.discription)
        setEditChips(rowData.checkpoints)
        setGoalEditDialog(true);
    };

    const handleEditCheckpoint = (rowData) => {
        setEditCheckpointSubmitted(false)
        setEditCheckpointId(rowData.checkpoint_id)
        setEditCheckpoint(rowData.name)
        setEditCheckpointDiscription(rowData.discription)
        setCheckpointEditDialog(true);
    };

    const accept = (goal_id) => {
        const deleteGoalURL = `${deleteGoalUrl}${goal_id}`

        axios
            .post(deleteGoalURL, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Goal Deleted', life: 3000 });
                setMounted(!mounted)       
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });

    }

    const acceptCheckpoint = (checkpoint_id) => {
        const deleteCheckpointURL = `${deleteCheckpointUrl}${checkpoint_id}`

        axios
            .post(deleteCheckpointURL, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Checkpoint Deleted', life: 3000 });
                setCheckpoints(response.data)
                setMounted(!mounted)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });

    }

    const acceptContact = (contact_id) => {
        const deleteContactURL = `${deleteContactUrl}${contact_id}`

        const body = {
            "goal_id" : contactGoal 
        }

        axios
            .post(deleteContactURL, body, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Contact Deleted', life: 3000 });
                setContacts(response.data.contacts)
                setColumns(response.data.column)
                setMounted(!mounted)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });

    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirmDeleteGoal = (goal_id) => {
        confirmDialog({
            message: 'Do you want to delete this Goal?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => accept(goal_id),
            reject
        });
    };

    const confirmDeleteCheckpoint = (checkpoint_id) => {
        confirmDialog({
            message: 'Do you want to delete this Checkpoint?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => acceptCheckpoint(checkpoint_id),
            reject
        });
    };

    const confirmDeleteContact = (contact_id) => {
        confirmDialog({
            message: 'Do you want to delete this Contact?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => acceptContact(contact_id),
            reject
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className='action-container'>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEditGoal(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteGoal(rowData.goal_id)} />
            </div>
        );
    };

    const checkpointActionBodyTemplate = (rowData) => {
        return (
            <div className='action-container'>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEditCheckpoint(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteCheckpoint(rowData.checkpoint_id)} />
            </div>
        );
    };

    const contactActionBodyTemplate = (rowData) => {
        return (
            <div className='action-container'>
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteContact(rowData.contact_id)} />
            </div>
        );
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddChip()
        }
    };

    const handleEditChipKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddEditChip()
        }
    };

    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code)
          .then(() => {
            toast.current.show({ severity: 'info', summary: 'Copied', detail: 'Code copied to the clipboard' });
          })
          .catch(err => {
            console.error('Failed to copy code to clipboard', err);
          });
      };

    const goalCodeTemplate = (rowData) => {
        return(
            <div className='status flex-copy'>
            <span className='code-link' onClick={()=> copyToClipboard(rowData.goal_id)}>{rowData.goal_id}</span> 
            </div>
        )
    };

    const checkpointCodeTemplate = (rowData) => {
        return(
            <div className='status flex-copy'>
            <span className='code-link' onClick={()=> copyToClipboard(rowData.checkpoint_id)}>{rowData.checkpoint_id}</span> 
            </div>
        )
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <span className='code-name' >{checkpointGoalName} - </span>
                <span className='code-link' onClick={()=> copyToClipboard(checkpointGoalId)}>{checkpointGoalId}</span>
            </div>
        );
    };

    const handelCheckpointReoarder = (e) => {
        setCheckpoints(e.value)
        const mappedArray = e.value.map((item, index) => {
            return {
                checkpoint_id: item.checkpoint_id,
                order: index + 1,
            };
        });

        const body = {
            "ordered_data" : mappedArray
        }
        
        axios
        .post(reorderCheckpoints, body, {
        headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
        if (response.status === 200){
            console.log('Rearranged')
        }
        else{
            console.log('Failed to rearrange')
        }
        })
        .catch((err) => {
            console.log('Failed to rearrange')
        });
        
    }

    const contactHeader = renderHeader();

    const auditLogHeader = auditLogRenderHeader();

    
    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="card">
                <DataTable ref={dt} value={products} dataKey="goal_id"  paginator rows={10} rowsPerPageOptions={[10, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} goals" globalFilter={globalFilter} header={header}>
                    <Column field="goal_id" header="Code" sortable style={{ minWidth: '12rem' }} body={goalCodeTemplate}></Column>
                    <Column field="title" header="Name" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="discription" header="Discription" style={{ minWidth: '16rem' }}></Column>
                    <Column field="num_checkpoints" header="Check Points" body={checkpointsBodyTemplate} style={{ minWidth: '16rem' }}></Column>
                    <Column field="num_contacts" header="Contacts" body={contactsBodyTemplate} style={{ minWidth: '16rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={productDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Add Goal" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={goal} onChange={(e) => setGoal(e.target.value)} required className={classNames({ 'p-invalid': submitted && !goal.trim() })} />
                    {submitted && !goal.trim() && <small className="p-error">This field is required</small>}
                </div>
                <div className="field">
                    <label htmlFor="description" className="font-bold">
                        Description
                    </label>
                    <InputTextarea id="description" value={goalDiscription} onChange={(e) => setGoalDiscription(e.target.value)} required rows={3} cols={20} />
                </div>
                <div className="field">
                    <label htmlFor="checkpoints" className="font-bold">
                        Check Points
                    </label>
                    <div className='chip-container'>
                    {chips.map((label, index) => (
                        <Chip key={index} label={label} icon='pi pi-times-circle' onClick={() => handleRemoveChip(label)} />
                    ))}
                    </div>
                    <div className="flex gap-2 p-inputgroup flex-1">
                        
                        <InputText id="checkpoint" value={newChipText} onChange={(e) => setNewChipText(e.target.value)} onKeyDown={handleKeyPress} placeholder="Enter a checkpoint" />
                        <Button icon="pi pi-check" className="p-button-success" onClick={handleAddChip}/>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={goalEditDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Edit Goal" modal className="p-fluid" footer={goalEditDialogFooter} onHide={hideEditDialog}>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={editGoal} onChange={(e) => setEditGoal(e.target.value)} required className={classNames({ 'p-invalid': editSubmitted && !editGoal.trim() })} />
                    {editSubmitted && !editGoal.trim() && <small className="p-error">This field is required</small>}
                </div>
                <div className="field">
                    <label htmlFor="description" className="font-bold">
                        Description
                    </label>
                    <InputTextarea id="description" value={editGoalDiscription} onChange={(e) => setEditGoalDiscription(e.target.value)} required rows={3} cols={20} />
                </div>
                <div className="field">
                    <label htmlFor="checkpoints" className="font-bold">
                        Check Points
                    </label>
                    <div className='chip-container'>
                    {editChips.map((label, index) => (
                        <Chip key={index} label={label} icon='pi pi-times-circle' onClick={() => handleRemoveEditChip(label)} />
                    ))}
                    </div>
                    <div className="flex gap-2 p-inputgroup flex-1">
                        
                        <InputText id="checkpoint" value={newEditChipText} onChange={(e) => setNewEditChipText(e.target.value)} onKeyDown={handleEditChipKeyPress} placeholder="Enter a checkpoint" />
                        <Button icon="pi pi-check" className="p-button-success" onClick={handleAddEditChip}/>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={checkpointEditDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Edit Checkpoint" modal className="p-fluid" footer={checkpointEditDialogFooter} onHide={hideCheckpointEditDialog}>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="name" value={editCheckpoint} onChange={(e) => setEditCheckpoint(e.target.value)} required className={classNames({ 'p-invalid': editCheckpointSubmitted && !editCheckpoint.trim() })} />
                    {editCheckpointSubmitted && !editCheckpoint.trim() && <small className="p-error">This field is required</small>}
                </div>
                <div className="field">
                    <label htmlFor="description" className="font-bold">
                        Description
                    </label>
                    <InputTextarea id="description" value={editCheckpointDiscription} onChange={(e) => setEditCheckpointDiscription(e.target.value)} required rows={3} cols={20} />
                </div>
            </Dialog>

            <Dialog visible={checkpointVisible} style={{ maxWidth: '95%' }} maximizable
                modal contentStyle={{ height: 'fit-content' }} onHide={() => setCheckpointVisible(false)}>
                <Toolbar className="mb-4 sticky" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={checkpoints} paginator rows={10} dataKey="checkpoint_id" scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} globalFilter={checkpointFilter} header={checkpointHeader}
                reorderableColumns reorderableRows onRowReorder={handelCheckpointReoarder}>
                    <Column rowReorder style={{ width: '3rem' }} />
                    <Column field="checkpoint_id" header="Code" sortable body={checkpointCodeTemplate}></Column>
                    <Column field="name" header="Check Point" sortable></Column>
                    <Column field="discription" header="Discription" style={{ minWidth: '16rem' }}></Column>
                    <Column  body={checkpointActionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </Dialog>

            <Dialog visible={contactVisible} style={{ maxWidth: '95%' }} maximizable
                modal contentStyle={{ height: 'fit-content' }} onHide={() => setContactVisible(false)}>
                <DataTable value={contacts} paginator rows={10} filters={filters} filterDisplay="row" loading={loading}
                    globalFilterFields={['full_name', 'email', 'phone']} header={contactHeader} emptyMessage="No contacts found.">
                    {columns.map((col, i) => (
                        col.header === 'Name' || col.header === 'Email' || col.header === 'Phone' ?
                            (
                                <Column key={col.field} field={col.field} header={col.header} />
                            ) :
                            (
                                <Column key={col.field} field={col.field} header={col.header} body={(e) => checkedBodyTemplate(e, col.field)} style={{ minWidth: '12rem' }}/>
                            )
                    ))}
                    <Column body={auditLogBodyTemplate} style={{ minWidth: '16rem' }}></Column>
                    <Column body={contactActionBodyTemplate} exportable={false} style={{ minWidth: '1rem' }}></Column>
                </DataTable>
            </Dialog>

            <Dialog visible={auditLogVisible} style={{ maxWidth: '95%' }} maximizable
                modal contentStyle={{ height: 'fit-content' }} onHide={() => setAuditLogVisible(false)}>
                <DataTable value={auditLogs} paginator rows={10} dataKey="formatted_timestamp" scrollable scrollHeight="flex" tableStyle={{ width: '100%' }} filters={auditLogFilters} filterDisplay="row" loading={auditLogLoading}
                 globalFilterFields={['checkpoint_name']} header={auditLogHeader}>
                    <Column field="formatted_timestamp" header="Date Time" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="checkpoint_name" header="Check Point" sortable filterField="checkpoint_name" showFilterMenu={false} filterMenuStyle={{ width: '1rem' }} filterElement={checkpointFilterTemplate} style={{ minWidth: '12rem' }}></Column>
                    <Column field="note" header="Note" style={{ minWidth: '20rem' }}></Column>
                </DataTable>
            </Dialog>
        </div>
    );
}
        